import './about.css'

const About = () => {

    return (
        <>
            <div className='about-container'>
                <div className="blog-container">
                    <div className="text-container">
                        <h1 className="blog-title">What is iv4.io</h1>
                        <p className="blog-paragraph">
                            This site is a passion project created by me, a network engineer who often has to check my public IP address.
                            <br />
                            It was overly redundant typing "what's my IP" into Google, and other sites' URLs were too long to type out repeatedly.
                            I created this website to provide other fellow network engineers with a quick and simple way to check their public IP addresses.
                        </p>
                        {/* Add more paragraphs as needed */}
                    </div>
                </div>
                <div className="blog-container">
                    <div className="text-container">
                        <h1 className="blog-title">Try This Windows Command</h1>
                        <p className="blog-paragraph">
                             <div className="code-wrapper">
                                start msedge https://iv4.io
                             </div>
                             <br />
                             <p>
                                This command opens Microsoft Edge and navigates directly to https://iv4.io.
                                <br />
                                You can run this command with either Command Prompt or PowerShell.
                                <br />
                                <i>Replace <b>msedge</b> with <b>chrome</b> if you prefer using Google Chrome.</i> 
                             </p>
                        </p>
                        {/* Add more paragraphs as needed */}
                    </div>
                </div>
                <div className="blog-container">
                    <div className="text-container">
                        <h1 className="blog-title">Future Plans</h1>
                        <p className="blog-paragraph">
                            Check back here for the latest updates, including new tools, guides, and other exciting features!
                            <br />
                            <i>Next update in 2024</i>
                        </p>
                        {/* Add more paragraphs as needed */}
                    </div>
                </div>
            </div>
        </>

    )
}

export default About
