import React, { useEffect, useState } from 'react';
import './App.css';
import './CSS-Custom.css';
import './blogTextFormatting.css'
import 'bootstrap/dist/css/bootstrap.min.css';
//import NavBar from './pageComponents/NavBar.js';
import IpData from './pageComponents/ipData.js'
import About from './pageComponents/about.js';
//import MacAddrData from './pageComponents/macAddrData.js';




function App() {
  

  const [selectedOption, setSelectedOption] = useState('home');

 
  useEffect(() => {
    console.log("Option in IpData:", selectedOption);
    
    
    // React to the change in selectedOption
  }, [selectedOption]);
  const appI =() => import('./pageFunctions/appInsights.js')

  const handleNavBarSelection = (value) => {
    // Additional logic based on the selection
    setSelectedOption(value)
  };

  const renderContent = () => {
    switch(selectedOption) {
      case 'home':
        return <IpData></IpData>
      case 'about':
        return <About />
        case 'getMacData':
        return null;
      // Add more cases as needed
      // Add more cases as needed
      default:
        return <IpData></IpData>; // Default case if none of the cases match
    }
  }



  return (
  
<>
<div className="App dark-mode">
      <div class="container">
        <div id="header" className="header">
          <h1 style={{cursor:'pointer'}} onClick={() => handleNavBarSelection('home')}>Iv4.io</h1>
        </div>
      </div>
  
      {/*<NavBar
      onSelection={handleNavBarSelection}
  ></NavBar>*/}
   {renderContent()}
    </div>
    {appI}

    </>
  );
}

export default App;
